import * as React from "react"
import { addPropertyControls, ControlType } from "framer"

// Main Breadcrumb Component
export function BreadcrumbList(props) {
    const { links, LinkComponent: LinkC, SeparatorComponent: SepC } = props
    const LinkComponent =
        LinkC?.[0]?.props?.children?.props?.children ?? LinkC?.[0] ?? null
    const SeparatorComponent = SepC[0]
    console.log(LinkComponent)
    console.log(SeparatorComponent)
    console.log(links)

    return (
        <nav aria-label="breadcrumb">
            <ol
                style={{
                    display: "flex",
                    listStyle: "none",
                    margin: 0,
                    padding: 0,
                }}
                itemScope
                itemType="https://schema.org/BreadcrumbList"
            >
                {links.map((link, index) => {
                    const isLast = index === links.length - 1
                    return (
                        <li
                            key={index}
                            style={{
                                display: "flex",
                                alignItems: "center",
                            }}
                            itemProp="itemListElement"
                            itemScope
                            itemType="https://schema.org/ListItem"
                        >
                            {LinkComponent &&
                                React.cloneElement(LinkComponent, {
                                    text: link.text,
                                    link: "../" + link.href,
                                    itemProp: "item",
                                })}

                            <meta itemProp="position" content={index + 1} />

                            {/* Separator Component */}
                            {!isLast &&
                                SeparatorComponent &&
                                React.cloneElement(SeparatorComponent)}
                        </li>
                    )
                })}
            </ol>
        </nav>
    )
}

// Register the Breadcrumb component for Framer
addPropertyControls(BreadcrumbList, {
    links: {
        title: "Links",
        type: ControlType.Array,
        description: "List of breadcrumb links",
        defaultValue: [
            { text: "Home", href: "/" },
            { text: "Products", href: "/products" },
            { text: "Shoes", href: "/products/shoes" },
        ],
        control: {
            type: ControlType.Object,
            controls: {
                text: {
                    title: "Text",
                    type: ControlType.String,
                    defaultValue: "Home",
                },
                href: {
                    title: "Link",
                    type: ControlType.Link,
                    defaultValue: "/",
                },
            },
        },
    },
    LinkComponent: {
        title: "Link Component",
        type: ControlType.ComponentInstance,
        description: "Custom link component",
    },
    SeparatorComponent: {
        title: "Separator Component",
        type: ControlType.ComponentInstance,
        description: "Custom separator component",
    },
})
